import React, { useCallback, useState } from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckGreenIcon, CloudUploadIcon, ContentCopyIcon, ForwardToInboxIcon } from '@src/components/utils/icomoon';

import { useAddReportTypeModal } from '../../report_type/add_report_type/add_report_type_modal';

import styles from './styles.module.scss';

interface IManualRevenueSystemSuccessModalProps extends IUseModalProps {
  revenueName: string,
  revenueSystem?: IRevenueSystem,
  revenueService?: IRevenueService,
}

const ManualRevenueSystemSuccessModal = ({
  isOpen,
  onDone,
  onCancel,
  revenueName,
  revenueSystem,
  revenueService,
  ...props
}: IManualRevenueSystemSuccessModalProps) => {
  const service = useRevenueServiceContext();

  const [badgeShow, setBadgeShow] = useState(false);

  const addReportTypeModal = useAddReportTypeModal();

  const copyEmail = useCallback(() => {
    navigator.clipboard.writeText(`${service.uploadEmail}`);
    setBadgeShow(true);
  }, [service.uploadEmail]);

  const handleMapNow = useCallback(() => {
    onDone();
    addReportTypeModal.open();
  }, [addReportTypeModal, onDone]);

  return (
    <>
      <Modal
        show={ isOpen }
        title="Revenue System Added!"
        { ...props }
      >
        <Modal.Body>
          <p className={ styles.headTitleText }>
            You have successfully added
            {' '}
            {revenueName}
            {' '}
            revenue system.
          </p>
          <p className={ styles.descriptionText }>
            Here&apos;s how you can effortlessly add daily revenue data:
          </p>

          <div className={ styles.optionContainer }>
            <div className={ styles.iconTextWrapper }>
              <ForwardToInboxIcon fontSize={ 20 } />
              <p className={ styles.headTitleText }>Upload from computer</p>
            </div>
            <p className={ styles.subText }>Upload directly to Revenue Capture</p>
          </div>

          <div className={ styles.orContainer }>
            <p className={ styles.orText }>or</p>
          </div>

          <div className={ styles.optionContainer }>
            <div className={ styles.iconTextWrapper }>
              <CloudUploadIcon fontSize={ 20 } />
              <p className={ styles.headTitleText }>Send to email</p>
            </div>
            <p className={ styles.subText }>Send your daily revenue reports to the below mail</p>
          </div>

          <div className={ styles.emailContainer }>
            <span className={ styles.emailText }>{service.uploadEmail}</span>

            <Button
              variant="link"
              onClick={ copyEmail }
            >
              <ContentCopyIcon fontSize={ 20 } />
            </Button>
            {badgeShow ? (
              <Text className={ styles['badge-copy'] } fontSize={ 12 }>
                <CheckGreenIcon fontSize={ 17 } />
                Copied to Clipboard
              </Text>
            ) : null}
          </div>

          <p className={ styles.finalStepText }>
            Next step is to map the report line items with the category,
            department and the journal entry. It will be saved as revenue report type after the mapping.
          </p>
        </Modal.Body>

        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Do it Later
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={ handleMapNow }
          >
            Map Now
          </Button>
        </Modal.Footer>
      </Modal>
      <addReportTypeModal.Component
        revenueService={ revenueService }
        revenueSystem={ revenueSystem }
        { ...addReportTypeModal.props }
      />
    </>
  );
};

const useManualRevenueSystemSuccessModal = makeUseModal(ManualRevenueSystemSuccessModal);

export { useManualRevenueSystemSuccessModal, ManualRevenueSystemSuccessModal as default };
