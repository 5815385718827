import React, { useCallback, useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetMemberInfo, useUpdateBusinessRole } from '@src/hooks/queries/team_management';
import { IUpdateBusinessRoleParams } from '@src/requests/team_management/team_management';
import { IBusinessTeamMember } from '@src/types/team_management/team_management';

import BaseModalMyTeam from '@src/components/settings/my_team/components/modal/base_modal_my_team';
import { Button } from '@src/components/ui_v2/buttons';
import { SelectInput } from '@src/components/ui_v2/inputs';
import { TOption } from '@src/components/ui_v2/inputs/types';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';

import ModalTitle from './modal_title';

import styles from './styles.module.scss';

interface IProfileModalProps extends IUseModalProps {
  member: IBusinessTeamMember;
}

const roleOptions: TOption[] = [
  { label: 'Business Admin', value: 'BusinessPartner' },
  { label: 'Employee', value: 'Employee' },
];

const ProfileModal = ({
  member,
  isOpen,
  onDone,
}: IProfileModalProps) => {
  const [selectedRole, setSelectedRole] = useState<TOption | null>(null);
  const { data: memberInfo, isLoading } = useGetMemberInfo({ id: member.id }, {
    enabled: isOpen,
  });

  const {
    mutate: updateBusinessRole,
    isLoading: isUpdatingRole,
  } = useUpdateBusinessRole();

  useEffect(() => {
    if (!memberInfo) return;

    const isEmployee = memberInfo.employeeInfos && Object.keys(memberInfo.employeeInfos).length > 0;
    const isBusinessPartner = memberInfo.partnerInfos && Object.keys(memberInfo.partnerInfos).length > 0;

    if (isEmployee) {
      setSelectedRole(roleOptions.find((option) => option.value === 'Employee') || roleOptions[0]);
    } else if (isBusinessPartner) {
      setSelectedRole(roleOptions.find((option) => option.value === 'BusinessPartner') || roleOptions[0]);
    }
  }, [memberInfo]);

  const handleStateChange = useCallback((option: TOption | null) => {
    if (memberInfo) {
      const isBusinessPartner = option?.value === 'BusinessPartner';
      const requestData = {
        id:         memberInfo.member.id,
        isAdmin:    isBusinessPartner,
        businessId: 0,
      };

      if (memberInfo.employeeInfos.length > 0) {
        const employeeInfo = memberInfo.employeeInfos[0];
        requestData.businessId = employeeInfo.businessId;
      } else if (memberInfo.partnerInfos.length > 0) {
        const partnerInfo = memberInfo.partnerInfos[0];
        requestData.businessId = partnerInfo.businessId;
      }
      updateBusinessRole(requestData as IUpdateBusinessRoleParams, {
        onSuccess: () => {
          setSelectedRole(option);
          toastr.success('Role updated successfully', 'Success');
        },
        onError: (error) => {
          toastr.error(error.message, 'Error');
        },
      });
    }
  }, [memberInfo, updateBusinessRole]);

  return (
    <BaseModalMyTeam
      footerContent={ (
        <Button
          variant="primary"
          onClick={ onDone }
        >
          Done
        </Button>
      ) }
      isOpen={ isOpen }
      modalTitle={ <ModalTitle member={ member } /> }
    >
      { !isLoading ? (
        <>
          <div className={ styles['profile-section'] }>
            <h3 className={ styles['section-title'] }>Profile Info</h3>
            <div className={ styles['info-row'] }>
              <div className={ styles['info-label'] }>Email</div>
              <div className={ styles['info-value'] }>{memberInfo?.member?.email || ''}</div>
            </div>

            <div className={ styles['info-row'] }>
              <div className={ styles['info-label'] }>Phone Number</div>
              <div className={ styles['info-value'] }>{memberInfo?.member?.phone || ''}</div>
            </div>

            <div className={ styles['info-row'] }>
              <div className={ styles['info-label'] }>Address</div>
              <div className={ styles['info-value'] }>{' '}</div>
            </div>
          </div>
          <div className={ styles['accounting-info-section'] }>
            <h3 className={ styles['section-title'] }>Accounting Info</h3>
            <div className={ styles['info-row'] }>
              <div className={ styles['info-label'] }>Role</div>
              <div className={ styles['info-value'] }>
                <SelectInput
                  isLoading={ isUpdatingRole }
                  isSearchable={ false }
                  menuPlacement="auto"
                  options={ roleOptions }
                  placeholder="Select Role"
                  value={ selectedRole }
                  onChange={ handleStateChange }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={ styles['loading-container'] }>
          <ProgressActivityLoader size="xlarge" />
        </div>
      ) }
    </BaseModalMyTeam>
  );
};

const useProfileModal = makeUseModal(ProfileModal);

export {
  IProfileModalProps,
  useProfileModal,
  ProfileModal as default,
};
