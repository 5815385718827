import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import {
  useCreateFinancialInstitutionConnection,
} from '@src/hooks/queries/financial_institution_connection';
import { useGetManagementGroupDefaultAggregator } from '@src/hooks/queries/management_groups';
import { LDFeatureForUserEnabled } from '@src/utils/config';

import Modal from '@src/components/ui/modal';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import ConnectNewFinancialInstitutionForm from './connect_new_financial_institution_form';
import { IConnectNewFinancialInstitutionData } from './schema';
import { useConnectAggregatorAccount } from '../hooks';

interface IConnectNewFinancialInstitutionModalProps {
  show: boolean;
  managementGroupId?: string | number; // Optional management group ID parameter
  onDone: () => void;
  onCancel: () => void;
}

const ConnectNewFinancialInstitutionModal = ({
  show,
  managementGroupId,
  onDone,
  onCancel,
}: IConnectNewFinancialInstitutionModalProps): JSX.Element => {
  const { mutate: getManagementGroupDefaultAggregator, isLoading } = useGetManagementGroupDefaultAggregator();

  // Track if we've initiated the connection process to prevent duplicate submissions
  const [connectionInitiated, setConnectionInitiated] = useState(false);

  const create = useCreateFinancialInstitutionConnection();
  const { mutate: createFinancialInstitution } = create;

  const connectNewFinancialInstitution = useCallback((
    data: IConnectNewFinancialInstitutionData,
  ) => {
    createFinancialInstitution({ financialInstitutionConnection: data }, {
      onSuccess: () => {
        setConnectionInitiated(false);
        onDone();
      },
    });
  }, [createFinancialInstitution, onDone]);

  const {
    connectAggregator,
    generateLinkMutation,
  } = useConnectAggregatorAccount({ onAggregatorConnected: connectNewFinancialInstitution });

  const handleSubmit = useCallback((data: IConnectNewFinancialInstitutionData) => {
    setConnectionInitiated(true);

    LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((isQuiltEnabled) => {
      if (isQuiltEnabled) {
        getManagementGroupDefaultAggregator({
          managementGroupId: Number(data.managementGroupId),
        }, {
          onSuccess: (response) => {
            connectAggregator(data, response.managementGroupDefaultAggregator.defaultAggregator, isQuiltEnabled);
            if (managementGroupId) {
              onDone();
            }
          },
          onError: (error) => {
            setConnectionInitiated(false);
            toastr.error(error.message, 'Error');
          },
        });
      } else {
        connectAggregator(data);
      }
    });
  }, [connectAggregator, getManagementGroupDefaultAggregator, managementGroupId, onDone]);

  // Auto-submit with management group ID if present
  if (show && managementGroupId && !connectionInitiated) {
    const data: IConnectNewFinancialInstitutionData = {
      managementGroupId: Number(managementGroupId),
    };
    handleSubmit(data);
  }

  // Reset state when modal is closed
  if (!show && connectionInitiated) {
    setConnectionInitiated(false);
  }

  // If we have a management group ID, don't render the form UI - just show a spinner
  if (managementGroupId) {
    return (
      <>
        {isLoading && <Spinner />}
        <MutationStatus mutation={ generateLinkMutation } />
        <MutationStatus mutation={ create } successMessage="New Financial Institution Connection was created successfully" />
      </>
    );
  }

  return (
    <>
      {isLoading && <Spinner /> }
      <MutationStatus mutation={ generateLinkMutation } />
      <MutationStatus mutation={ create } successMessage="New Financial Institution Connection was created successfully" />
      <Modal.Form
        className="connect-financial-institution-modal"
        proceedTitle="Continue"
        show={ show }
        title="Select Management Group"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <ConnectNewFinancialInstitutionForm
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

export default ConnectNewFinancialInstitutionModal;
