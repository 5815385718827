import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpsertIntegration, useSetIntegrationPaused } from '@src/hooks/queries/integrations/integrations';
import { IUpsertIntegrationResponse } from '@src/requests/integrations/integrations';
import { TID } from '@src/types/common';
import { IExternalSystemPullProperties } from '@src/types/integrations/integrations';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import Form from './form';
import { usePushIntegrationSuccessModel } from './push_integration_success_model';
import { IPushIntegrationConnectValues } from './schema';
import { useConnectRevenueSystemModal } from '../connect_system/connect_system_modal';

interface IPushIntegrationConnectModelProps extends IUseModalProps {
  externalSystemId: TID,
  externalSystemName: string
  skipRevenueMapping?: boolean,
  id?: TID,
  isEdit?: boolean,
  valueForm?: IPushIntegrationConnectValues,
  externalSystemConfigSchema: IExternalSystemPullProperties,
}

const PushIntegrationConnectModel = ({
  isOpen,
  onDone,
  onCancel,
  externalSystemId,
  externalSystemName,
  skipRevenueMapping,
  isEdit = false,
  valueForm,
  id,
  externalSystemConfigSchema,
  ...props
}: IPushIntegrationConnectModelProps) => {
  const [integration, setIntegration] = useState<IUpsertIntegrationResponse | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pushIntegrationSuccessModel = usePushIntegrationSuccessModel();
  const business = useBusinessContext();

  const upsert = useUpsertIntegration();
  const { mutate } = upsert;

  const setAsActive = useSetIntegrationPaused();
  const { mutate: setAsActiveMutate } = setAsActive;

  const handleError = useCallback((response) => {
    setIsLoading(false);
    toastr.error(response?.response?.data?.errors[0], 'Error');
  }, []);

  const connectSystemModal = useConnectRevenueSystemModal();

  const connectPushSystem = useCallback((data: IPushIntegrationConnectValues) => {
    setIsLoading(true);
    mutate({
      businessId:  business.id,
      integration: {
        externalSystemId,
        revenueReportTypeId:  data.revenueReportTypeId,
        startDate:            data.startDate,
        location:             data.location,
        externalSystemConfig: {},
        id:                   id || undefined,
      },
    }, {
      onSuccess: (response) => {
        setIsLoading(false);
        if (response.emailAddress) {
          setIntegration(response);
          if (isEdit) {
            toastr.success(
              'Configuration details have been updated',
              'Successfully updated',
            );
          } else {
            pushIntegrationSuccessModel.open();
          }
          onDone();
          if (response.skipRevenueMapping) {
            setAsActiveMutate({ integrationId: response.id, isPaused: false });
          }
        }
      },
      onError: handleError,
    });
  }, [
    mutate,
    business.id,
    externalSystemId,
    id,
    handleError,
    isEdit,
    onDone,
    pushIntegrationSuccessModel,
    setAsActiveMutate]);

  const handleOnCancel = useCallback(() => {
    if (!isEdit) {
      connectSystemModal.open();
    }
    onCancel();
  }, [connectSystemModal, onCancel, isEdit]);

  return (
    <>

      <connectSystemModal.Component
        { ...connectSystemModal.props }
      />
      <pushIntegrationSuccessModel.Component
        UpsertIntegrationResponse={ integration }
        { ...pushIntegrationSuccessModel.props }
      />
      <Modal
        show={ isOpen }
        title={ `Integrate with ${externalSystemName} ${skipRevenueMapping ? 'System' : 'Revenue System'}` }
        { ...props }
      >
        <>
          <Modal.Body>
            <p>
              Select the starting date for the
              {' '}
              {skipRevenueMapping ? 'system' : 'revenue system'}
              .
            </p>
            <Form
              business={ business }
              externalSystemConfigSchema={ externalSystemConfigSchema }
              formId="edit_linked_businesses_form"
              valueForm={ valueForm }
              onSubmit={ connectPushSystem }
              onValidationChange={ setIsFormValid }
            />
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ handleOnCancel }
            >
              Back
            </Button>
            <Button
              disabled={ !isFormValid || isLoading }
              form="edit_linked_businesses_form"
              suffixIcon={ isLoading ? <SpinnerIcon spin fontSize={ 20 } /> : '' }
              type="submit"
              variant="primary"
            >
              {(!isEdit) ? 'Connect' : 'Save'}
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

const usePushIntegrationConnectModel = makeUseModal(PushIntegrationConnectModel);

export {
  usePushIntegrationConnectModel,
  PushIntegrationConnectModel as default,
};
