import React, { useState, useEffect, useMemo, useCallback } from 'react';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetAllClients, useManageClientAssignments } from '@src/hooks/queries/team_management';
import { IManageClientAssignmentsParams } from '@src/requests/team_management/team_management';
import { IBusinessTeamMember, IClientBusiness } from '@src/types/team_management/team_management';

import BaseModalMyTeam from '@src/components/settings/my_team/components/modal/base_modal_my_team';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';
import { EditClientIcon } from '@src/components/utils/icomoon';

import AnimatedLoaderModal from './animated_loader_modal';
import ModalTitle from './modal_title';

import styles from './styles.module.scss';

const PAGE_SIZE = 200;

interface IEditClientModalProps extends IUseModalProps {
  member: IBusinessTeamMember;
}

const EditClientModal = ({
  member,
  isOpen,
  onDone,
  onCancel,
}: IEditClientModalProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedClients, setSelectedClients] = useState<IClientBusiness[]>([]);
  const [deselectedClients, setDeselectedClients] = useState<IClientBusiness[]>([]);
  const { mutate: manageClientAssignments, isLoading: isManagingClientAssignments } = useManageClientAssignments();
  const query = useGetAllClients({
    id:      member.id,
    perPage: PAGE_SIZE,
  }, {
    enabled:          isOpen,
    staleTime:        0,
    keepPreviousData: false,
    refetchOnMount:   'always',
  });
  const { data: clientsData, isLoading } = query;

  // Initialize selected clients when data is loaded
  useEffect(() => {
    if (clientsData?.businesses) {
      setSelectedClients(clientsData.businesses);
      setDeselectedClients([]); // Reset deselected clients when data changes
    }
  }, [clientsData]);

  // Filter clients based on search term
  const filteredCheckedBusinesses = useMemo(() => {
    if (!clientsData?.businesses) return [];

    if (!searchTerm) return clientsData.businesses;

    return clientsData.businesses.filter((client) => client.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [clientsData, searchTerm]);

  const filteredAvailableBusinesses = useMemo(() => {
    if (!clientsData?.availableBusinesses || !clientsData?.businesses) return [];

    // Get the IDs of businesses that are already in the checked list
    const existingBusinessIds = clientsData.businesses.map((business) => business.id);

    // Filter out available businesses that already exist in the businesses list
    const uniqueAvailableBusinesses = clientsData.availableBusinesses.filter(
      (business) => !existingBusinessIds.includes(business.id),
    );

    if (!searchTerm) return uniqueAvailableBusinesses;

    return uniqueAvailableBusinesses.filter((client) => client.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [clientsData, searchTerm]);

  // Check if a client is selected
  const isClientSelected = useCallback((clientId: number) => {
    return selectedClients.some((client) => client.id === clientId);
  }, [selectedClients]);

  // Toggle client selection
  const toggleClientSelection = useCallback((client: IClientBusiness) => {
    // Check if the client was initially selected (exists in clientsData.businesses)
    const wasInitiallySelected = clientsData?.businesses?.some((b) => b.id === client.id) || false;

    if (isClientSelected(client.id)) {
      // Client is being deselected
      setSelectedClients((prev) => prev.filter((c) => c.id !== client.id));

      // Only add to deselectedClients if it was initially selected
      if (wasInitiallySelected) {
        setDeselectedClients((prev) => [...prev, client]);
      }
    } else {
      // Client is being selected
      setSelectedClients((prev) => [...prev, client]);

      // Remove from deselectedClients if it was there
      setDeselectedClients((prev) => prev.filter((c) => c.id !== client.id));
    }
  }, [clientsData, isClientSelected]);

  // Handle save changes
  const handleSaveChanges = useCallback(() => {
    // Prepare the request data
    const newSelectedClients = filteredAvailableBusinesses.filter((client) => isClientSelected(client.id));

    // Group added clients by serviceProviderBusinessId
    const addedClientGroups: Record<number, number[]> = {};
    newSelectedClients.forEach((client) => {
      if (!addedClientGroups[client.serviceProviderBusinessId]) {
        addedClientGroups[client.serviceProviderBusinessId] = [];
      }
      addedClientGroups[client.serviceProviderBusinessId].push(client.id);
    });

    // Group removed clients by serviceProviderBusinessId
    const removedClientGroups: Record<number, number[]> = {};
    deselectedClients.forEach((client) => {
      if (!removedClientGroups[client.serviceProviderBusinessId]) {
        removedClientGroups[client.serviceProviderBusinessId] = [];
      }
      removedClientGroups[client.serviceProviderBusinessId].push(client.id);
    });

    // close modal
    onDone();
    if (Object.keys(addedClientGroups).length === 0 && Object.keys(removedClientGroups).length === 0) {
      return;
    }

    const requestData: IManageClientAssignmentsParams = {
      userId:       member.id,
      addedClients: Object.entries(addedClientGroups).map(([serviceProviderBusinessId, clientIds]) => ({
        clientIds,
        serviceProviderBusinessId: Number(serviceProviderBusinessId),
      })),
      removedClients: Object.entries(removedClientGroups).map(([serviceProviderBusinessId, clientIds]) => ({
        clientIds,
        serviceProviderBusinessId: Number(serviceProviderBusinessId),
      })),
    };

    manageClientAssignments(requestData, {
      onSuccess: () => {
        toastr.success('Success', 'Clients updated successfully');
      },
      onError: (error) => {
        toastr.error('Error', error.message);
      },
    });
  }, [
    deselectedClients,
    filteredAvailableBusinesses,
    isClientSelected,
    member.id,
    onDone,
    manageClientAssignments,
  ]);

  const handleCancel = useCallback(() => {
    setSearchTerm('');
    if (clientsData?.businesses) {
      setSelectedClients(clientsData.businesses);
      setDeselectedClients([]);
    } else {
      setSelectedClients([]);
      setDeselectedClients([]);
    }
    onCancel();
  }, [
    clientsData,
    onCancel,
  ]);

  const hasSearchResults = filteredCheckedBusinesses.length > 0 || filteredAvailableBusinesses.length > 0;

  return (
    <>
      <AnimatedLoaderModal isOpen={ isManagingClientAssignments } />
      <BaseModalMyTeam
        className={ styles['edit-client-modal-body'] }
        footerContent={ (
          <div className={ styles['edit-client-modal-footer'] }>
            <Button
              variant="link"
              onClick={ handleCancel }
            >
              Cancel
            </Button>
            <Button
              disabled={ isManagingClientAssignments }
              variant="primary"
              onClick={ handleSaveChanges }
            >
              Save Changes
            </Button>
          </div>
        ) }
        isOpen={ isOpen }
        modalTitle={ <ModalTitle member={ member } /> }
      >
        {!isLoading ? (
          <div className={ styles['edit-client-container'] }>
            <div className={ styles['search-container'] }>
              <SearchInput
                placeholder="Search client"
                value={ searchTerm }
                onChange={ setSearchTerm }
              />
            </div>

            {hasSearchResults ? (
              <div
                className={ styles['clients-list'] }
              >
                {/* Checked businesses */}
                <div className={ styles['clients-list-checked'] }>
                  {filteredCheckedBusinesses.length > 0 && (
                    <>
                      {filteredCheckedBusinesses.map((client) => (
                        <div key={ client.id } className={ styles['client-item'] }>
                          <CheckboxInput
                            checked={ isClientSelected(client.id) }
                            id={ `client-${client.id}` }
                            title={ client.name }
                            onChange={ () => toggleClientSelection(client) }
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {/* Separator - only show if both lists have items */}
                {filteredCheckedBusinesses.length > 0 && filteredAvailableBusinesses.length > 0 && (
                  <div className={ styles['client-separator'] } />
                )}

                {/* Available businesses */}
                {filteredAvailableBusinesses.length > 0 && (
                  <>
                    {filteredAvailableBusinesses.map((client) => (
                      <div key={ client.id } className={ styles['client-item'] }>
                        <CheckboxInput
                          checked={ isClientSelected(client.id) }
                          id={ `client-${client.id}` }
                          title={ client.name }
                          onChange={ () => toggleClientSelection(client) }
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : (
              <div className={ styles['no-results'] }>
                <div className={ styles['no-results-icon'] }>
                  <EditClientIcon fontSize={ 40 } />
                </div>
                No clients found
              </div>
            )}
          </div>
        ) : (
          <div className={ styles['loading-container'] }>
            <ProgressActivityLoader size="xlarge" />
          </div>
        )}
      </BaseModalMyTeam>
    </>
  );
};

const useEditClientModal = makeUseModal(EditClientModal);

export {
  IEditClientModalProps,
  useEditClientModal,
  EditClientModal as default,
};
